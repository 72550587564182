<template>
  <lf-card class="max-w-screen-md py-6 mb-8" data-cy="lender-network-list">
    <template #header>
      <div class="flex flex-col">
        <lf-h3>
          {{ $t("LENDER_NETWORK.TITLE") }}
        </lf-h3>
        <span>
          {{
            isLendflowUser
              ? $t("LENDER_NETWORK.SETTING_DESCRIPTION.LENDFLOW")
              : $t("LENDER_NETWORK.SETTING_DESCRIPTION.CLIENT")
          }}
        </span>
      </div>
    </template>
    <loader :is-loading="showLoader" />

    <div
      v-if="isLendflowUser"
      class="space-y-6"
      data-cy="lendflow-lender-network"
    >
      <lf-switch
        name="selectAll"
        v-model="lenderNetworkEnabled"
        :disabled="!canManageLenderNetwork"
        @toggle-changed="toggleClientLenderNetwork"
      >
        <lf-h4>
          {{ $t("LENDER_NETWORK.SETTING_SWITCH") }}
        </lf-h4>
      </lf-switch>

      <div v-if="lenderNetworkEnabled">
        <div
          class="bg-disabled p-6 -mx-6 border-t border-b shadow-inner space-y-4"
        >
          <div class="flex items-center justify-between">
            <div class="flex space-x-2">
              <lf-checkbox
                :disabled="!canManageLenderNetwork"
                name="selectAllClients"
                value=""
                v-model="selectAllValue"
              >
                <span class="text-gray-400">
                  {{ $t("LENDER_NETWORK.SELECT_ALL_TITLE") }}
                </span>
              </lf-checkbox>
            </div>

            <div class="border">
              <search-input
                :model-value="filters.search"
                no-margin
                no-padding
                @update:model-value="searchLenders"
              />
            </div>
          </div>

          <div class="flex flex-col space-y-4">
            <div class="grid grid-cols-2 gap-y-4">
              <div v-for="client in sortedLenders" :key="client.id">
                <lf-checkbox
                  v-model="selectedLenders"
                  :disabled="!canManageLenderNetwork"
                  name="selectedLenders"
                  :value="client.id"
                  data-cy="select-lender-checkbox"
                >
                  {{ client.name }}
                </lf-checkbox>
              </div>
            </div>

            <lf-link
              v-if="filters.page < lastPage"
              class="flex items-center justify-center w-full"
              @click="getLenders"
            >
              {{ $t("COMMON.LOAD_MORE") }}
            </lf-link>
          </div>
        </div>
        <div class="flex justify-end pt-6">
          <primary-button
            :disabled="!canManageLenderNetwork"
            @click="saveLenderNetwork"
          >
            {{ $t("COMMON.SAVE") }}
          </primary-button>
        </div>
      </div>
    </div>

    <div
      v-else-if="selectedLenders.length"
      class="grid grid-cols-2 gap-y-4"
      data-cy="client-lender-network"
    >
      <template v-for="client in selectedLenders" :key="JSON.stringify(client)">
        <div
          v-if="typeof client === 'object'"
          class="flex space-x-2 items-center"
          data-cy="client-lender"
        >
          <icon-base
            :icon="IconCheckCircle"
            :icon-color="UTIL_COLORS.SUCCESS"
          />
          <span class="text-gray-600">{{ client.name }}</span>
        </div>
      </template>
    </div>

    <div v-else class="flex items-center justify-center" data-cy="empty-state">
      <div class="flex flex-col space-y-2 items-center max-w-63 py-10">
        <icon-base :icon="IconLenderNetworkEmpty" width="72" height="73" />
        <lf-h4>{{ $t("LENDER_NETWORK.CONTACT_SUPPORT") }}</lf-h4>
        <span class="text-center">
          {{ $t("LENDER_NETWORK.NO_DATA") }}
        </span>
      </div>
    </div>
  </lf-card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useAuth, usePermissions } from "@/hooks/auth";
import { UTIL_COLORS } from "@/helpers/constants";
import { PermissionSubject } from "@/enums/auth";
import { Ability } from "@/enums/auth";
import { useStore } from "vuex";
import { usePromiseWrapper } from "@/hooks/common";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";
import clientsApiService from "@/services/modules/clients";
import lenderNetworkService from "@/services/modules/lenderNetwork";
import LfH3 from "@/components/ui/text/LfHeading3.vue";
import LfH4 from "@/components/ui/text/LfHeading4.vue";
import LfCard from "@/components/ui/LfCard.vue";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import LfSwitch from "@/components/ui/inputs/LfSwitch.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconLenderNetworkEmpty from "@/components/icons/IconLenderNetworkEmpty.vue";
import Loader from "@/components/ui/Loader.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";
import IconCheckCircle from "@/components/icons/IconCheckCircle.vue";
import type { SimpleClient, IClient } from "@/models/clients";
import LfLink from "@/components/ui/LfLink.vue";
import orderBy from "lodash/orderBy";

interface Filters {
  search: string;
  page: number;
}

const { isLendflowUser } = useAuth();
const { dispatch, getters } = useStore();
const { canPerformActionReactive } = usePermissions();
const { showMessage } = useNotification();
const { t } = useI18n();
const activeClient = computed<IClient | null>(() => getters["clients/active"]);

const allLenders = ref<IClient[]>([]);
const lastPage = ref(1);
const selectedLenders = ref<SimpleClient[] | IClient["id"][]>([]);
const lenderNetworkEnabled = ref(!!activeClient.value?.lender_network_enabled);
const filters = ref<Filters>({
  search: "",
  page: 0
});

const canManageLenderNetwork = canPerformActionReactive(
  PermissionSubject.client,
  Ability.manageLenderNetwork,
  { client: activeClient.value?.id }
);

const selectAllValue = computed({
  get() {
    return allLenders.value.every((client) =>
      (selectedLenders.value as string[]).includes(client.id)
    );
  },
  set(isChecked) {
    selectedLenders.value = isChecked
      ? allLenders.value.map((client) => client.id)
      : [];
  }
});

const sortedLenders = computed(() =>
  orderBy(allLenders.value, (client) =>
    (selectedLenders.value as string[]).includes(client.id) ? 0 : 1
  )
);

const showLoader = computed(
  () =>
    lendersLoading.value ||
    toggleLoading.value ||
    isLenderNetworkProcessed.value ||
    lenderNetworkLoading.value
);

const searchLenders = (value: string) => {
  filters.value = {
    search: value.trim(),
    page: 0
  };
  allLenders.value = [];
  getLenders();
};

const { fetchWrapper: saveLenderNetwork, loading: isLenderNetworkProcessed } =
  usePromiseWrapper(async () => {
    if (!isLendflowUser) {
      return;
    }
    await dispatch("clients/attachLenders", { lenders: selectedLenders.value });
  });

const { fetchWrapper: toggleClientLenderNetwork, loading: toggleLoading } =
  usePromiseWrapper(
    async (value: boolean) => {
      await dispatch("clients/updateClient", {
        data: { lender_network_enabled: value }
      });
      if (value) {
        await getLenders();
      } else {
        allLenders.value = [];
        filters.value = { search: "", page: 0 };
      }
    },
    {
      onError: () => {
        showMessage(t("COMMON.ERROR_OCCURRED"), "error");
        lenderNetworkEnabled.value = !lenderNetworkEnabled.value;
      }
    }
  );

const { fetchWrapper: getLenders, loading: lendersLoading } = usePromiseWrapper(
  async () => {
    filters.value.page++;
    if (filters.value.page > lastPage.value) {
      filters.value.page = lastPage.value;
      return;
    }
    const { search, page } = filters.value;
    const { data, meta } = await clientsApiService.getClients({
      is_funder: 1,
      page,
      search,
      per_page: 100
    });
    allLenders.value.push(...data);
    lastPage.value = meta.last_page;
  }
);

const { fetchWrapper: getLenderNetwork, loading: lenderNetworkLoading } =
  usePromiseWrapper(lenderNetworkService.show);

onMounted(async () => {
  if (isLendflowUser && lenderNetworkEnabled.value) {
    await getLenders();
  }

  const lenderNetworkId = activeClient.value?.lender_network?.id;
  if (lenderNetworkId) {
    const { data } = await getLenderNetwork(lenderNetworkId);

    selectedLenders.value = isLendflowUser
      ? data.data.lenders.map((lender) => lender.id)
      : data.data.lenders;
  }
});
</script>

<style scoped>
.icon-arrow {
  @apply cursor-pointer transform translate-y-0-25;

  &:deep(rect) {
    display: none;
  }
}
</style>
